.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.form-group+.form-type-checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.form-control {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-sizing: border-box;
    font-size: 0.9rem;
    padding: 0.5rem;
    width: 100%;
}

.form-type-checkbox .form-control {
    width: 17px;
    height: 17px;
}

.form-control:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

button {
    cursor: pointer;
}

label {
    display: block;
    align-items: center;
    font-size: 0.9rem;
    margin-bottom: 0.4rem;
}

.form-type-checkbox label {
    display: flex;
    align-items: center;
    margin: 0 0 0 0.4em;
}